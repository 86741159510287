import styled from 'styled-components';

export const Container = styled.p`
width: 100%;
transition: 1s;
font-family: 'Boogaloo';
font-size: 16px;
color: #F4BA15;
margin: 5px;

 b{ 
    font-family: 'Boogaloo';
    color: #E0692B;
 }
`;